import React from 'react';

import { Container } from '@material-ui/core';

import { PricingIntro } from './PricingIntro';
import { PricingTable } from './PricingTable';
import { CtaCard } from '../General/CtaCard';

export const PricingBody = ({ overview, pricingTable, caseStudy }) => {
	const {
		ctaHeader,
		ctaSubheader,
		ctaText,
		ctaBackground,
		ctaLink,
		internalLink,
	} = caseStudy;
	return (
		<>
			<PricingIntro overview={overview} />
			<PricingTable pricingTable={pricingTable} />
			{/* <Container>
				<CtaCard
					background={ctaBackground}
					header={ctaHeader}
					subheader={ctaSubheader}
					ctaText={ctaText}
					ctaLink={ctaLink}
					internalLink
				/>
			</Container> */}
		</>
	);
};
