import React, { useContext } from 'react';
import PortableText from 'react-portable-text';

import { Grid, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import FormModalContext from '../../context/FormModalContext';

const useStyles = makeStyles((theme) => ({
	header: {
		color: theme.workwaveBlue,
		fontSize: '2.25rem',
		fontWeight: 700,
	},
	stylized: {
		fontSize: '1.4rem',
		fontWeight: 800,
		color: '#2a7abc',
		// textTransform: 'uppercase',
		[theme.breakpoints.down('md')]: {
			fontSize: '1.3rem',
		},
	},
	paragraph: {
		lineHeight: '27px',
		fontWeight: 500,
		marginTop: '1rem',
		[theme.breakpoints.down('lg')]: {
			marginRight: '4em',
		},
		[theme.breakpoints.down('md')]: {
			marginRight: '0',
		},
	},
}));

export const PricingIntro = ({ overview }) => {
	const classes = useStyles();
	const { handleModalClick } = useContext(FormModalContext);
	return (
		<Container style={{ paddingBottom: '4rem' }}>
			<Grid container justifyContent='center' style={{ textAlign: 'center' }}>
				<Grid item lg={10}>
					<PortableText
						content={overview}
						// className={classes.text}
						serializers={{
							h2: ({ children }) => (
								<Typography
									variant='h2'
									color='primary'
									className={classes.header}
									style={{ lineHeight: '1.0' }}>
									{children}
								</Typography>
							),
							em: ({ children }) => (
								<span className={classes.stylized}>{children}</span>
							),
							normal: ({ children }) => (
								<Typography variant='body1' className={classes.paragraph}>
									{children}
								</Typography>
							),
							link: ({ children }) => (
								<span
									style={{ cursor: 'pointer', textDecoration: 'underline' }}
									onClick={(e) => handleModalClick(e)}>
									{children}
								</span>
							),
						}}
					/>
				</Grid>
			</Grid>
		</Container>
	);
};
