import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	Grid,
	Typography,
	Button,
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Collapse,
	IconButton,
	withStyles,
	useMediaQuery,
	SvgIcon,
	Container,
	useTheme,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles((theme) => ({
	featureCell: {
		cursor: 'pointer',
		userSelect: 'none',
	},
	featureWrapper: {
		display: 'flex',
		marginLeft: '1rem',
	},
	featureDescription: {
		margin: '.5rem 0 0 1.8rem',
		fontSize: '1.125rem',
		fontWeight: 400,
		color: '#5A7184',
	},
	compareTitleCell: {
		borderTopLeftRadius: '22px',
		borderTopRightRadius: '22px',
		borderColor: '#ffffff !important',
		borderWidth: 0,
		borderStyle: 'solid',
	},
	headerCell: {
		background: '#002D5C',
		// borderRadius: '20px 0px 0px 0px',
	},
	header: {
		fontWeight: 600,
		marginLeft: '1rem',
		color: 'white',
	},
	sectionHeader: {
		fontWeight: 700,
		color: theme.workwaveBlue,
	},
	subHeader: {
		color: theme.workwaveBlue,
	},
	button: {
		cursor: 'pointer',
		opacity: 1,
		textDecoration: 'none',
		transition: 'all .5s',
		'&:hover': {
			opacity: 0.8,
		},
	},
	expandText: {
		cursor: 'pointer',
		textAlign: 'center',
		paddingBottom: '4rem',
		fontWeight: 600,
		opacity: 1,
		transition: 'all .5s',
		paddingTop: '2rem',
		'&:hover': {
			opacity: 0.8,
		},
	},
	headerCell: {
		background: theme.workwaveBlue,
		display: 'flex',
		justifyContent: 'center',
		border: `2px solid ${theme.workwaveBlue}`,
		padding: '1.5rem 0',
	},
}));

const StyledCell = withStyles({
	root: {
		border: '2px solid #C3CAD9',
	},
})(TableCell);

const StyledBody = withStyles({
	root: {
		borderRadius: '0px 20px 20px 20px',
	},
})(TableBody);

const Content = ({ content }) => {
	const [open, setOpen] = useState(false);

	const classes = useStyles();

	const { contentMain, contentBody } = content;

	return (
		<StyledCell
			component='th'
			scope='row'
			onClick={() => setOpen(!open)}
			className={classes.featureCell}>
			<div className={classes.featureWrapper}>
				<Typography
					variant='h6'
					color='primary'
					style={{ fontSize: '1.4rem', fontWeight: 600, padding: '10px' }}>
					{contentMain}
				</Typography>
				<IconButton
					aria-label='expand row'
					size='small'
					color='secondary'
					style={{ marginLeft: '.5rem' }}>
					{open ? <RemoveIcon /> : <AddIcon />}
				</IconButton>
			</div>
			<Collapse in={open} timeout='auto' unmountOnExit>
				<Typography variant='body1' className={classes.featureDescription}>
					{contentBody}
				</Typography>
			</Collapse>
		</StyledCell>
	);
};

export const PricingTable = ({ pricingTable }) => {
	const [expanded, setExpanded] = useState(false);
	const md = useMediaQuery('(max-width: 960px)');
	const theme = useTheme();
	const classes = useStyles();
	return (
		<Container>
			{pricingTable.map((section, index) => (
				<>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell
										className={classes.headerCell}
										style={{
											borderRadius: '1.25rem 1.25rem 0rem 0rem',
											marginTop: index !== 0 && '2rem',
										}}>
										<Typography variant='h4' style={{ color: '#FFF' }}>
											{section.contentHeader}
										</Typography>
									</TableCell>
								</TableRow>
							</TableHead>
							<StyledBody>
								{section.pricingTableContent.map((content, index) => (
									<TableRow>
										<Content content={content} key={index} />
									</TableRow>
								))}
							</StyledBody>
						</Table>
					</TableContainer>
				</>
			))}
		</Container>
	);
};
